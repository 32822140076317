import React from "react";
import "../AutomationTesting_Page/AutomationTesting.css";
import Footer from "../HomePage/Footer/Footer";
import { NavLink } from "react-router-dom";
export default function AutomationTesting() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div id="marg_in">
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <ul id="listt_link">
                <NavLink
                  to="/mobile-app-development"
                  className="a_dec"
                  id="service_1"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Mobile App Development</li>
                </NavLink>
                <NavLink
                  to="/android-app-development"
                  className="a_dec"
                  id="service_2"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Android App Development</li>
                </NavLink>
                <NavLink
                  to="/ios-development"
                  className="a_dec"
                  id="service_3"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">IOS Development</li>
                </NavLink>
                <NavLink
                  to="/flutter-app-development"
                  className="a_dec"
                  id="service_4"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Flutter App Development</li>
                </NavLink>
                <NavLink
                  to="/AI-solution"
                  className="a_dec"
                  id="service_5"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">AI Solution</li>
                </NavLink>
                <NavLink
                  to="/web-development"
                  className="a_dec"
                  id="service_6"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Web Development</li>
                </NavLink>
                <NavLink
                  to="/devops"
                  className="a_dec"
                  id="service_7"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Devops</li>
                </NavLink>
                <NavLink
                  to="/automation-testing"
                  className="a_dec"
                  id="service_8"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li
                    id="li_des"
                    style={{
                      backgroundImage:
                        "linear-gradient(-45deg, orange 0%, white 100%)",
                      fontWeight: "bold",
                    }}
                  >
                    Automation Testing
                  </li>
                </NavLink>
                <NavLink
                  to="/webscraping"
                  className="a_dec"
                  id="service_9"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Web Scrapping</li>
                </NavLink>
              </ul>
            </div>

            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
              <div className="container" id="content_design">
                <h2 id="mad_head">
                  <span style={{ color: "orange", fontSize: "20px" }}>
                    Automation{" "}
                  </span>
                  Testing
                </h2>
                <p style={{ textIndent: "50px" }}>
                  Szigony Technologies delivers software testing services and
                  quality assurance services for desktop and mobile
                  applications, to drive quality to your product. Our testing
                  experts work directly with your team to create an end-to-end
                  process that works consistently and rapidly while providing
                  you dedicated support and seamless feedback. Our services are
                  directed to help you achieve quality at speed and ensure that
                  you have a secure online existence.
                </p>
                <p>
                  <strong style={{ color: "black" }}>
                    Technologies and Tools:
                  </strong>{" "}
                  Python, Perl, Shell Scripting, Selenium, REST APIs, Katalon,
                  Selenium webdriver, SmartBear test complete, Skill test,
                  Watir,Load Runner, Apache Jmeter, Test Director, Test Link,
                  Atlassian Confluence, Atlassian JIRA, Atlassian Bamboo,
                  Mantis, Bugzilla, Jenkins
                </p>
                <h2 style={{ color: "black" }}>
                  We are doing the following testing services:
                </h2>
                <ul id="AT_ul" style={{ marginLeft: "-30px" }}>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Unit testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Integration testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    System testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Sanity testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Smoke testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Interface testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Regression testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Beta/Acceptance testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Web application testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Mobile application testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    API testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Chrome extension testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Functional testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Keyword testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Data driven testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Black box testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Security testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Usability testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    UI testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Acceptance testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Cross platform testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    End-to-End testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Load and Stress testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Data Migration testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Localisation and internationalisation testing
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Featured Case Studies
                  </li>
                </ul>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                    <NavLink
                      to="/service"
                      id="fa_siz_center"
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      All services
                    </NavLink>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
