import React from "react";
import PrivacyAndPolicy from "../../Components/PrivacyPolicy/PrivacyAndPolicy";
import Footer1 from "../../Components/HomePage/Footer1/Footer1";

export default function PrivacyPolicy() {
  return (
    <div>
      <PrivacyAndPolicy />
      <Footer1 />
    </div>
  );
}
