import React from "react";
import Footer1 from "../../Components/HomePage/Footer1/Footer1";
import ServiceContent from "../../Components/ServicePage/ServiceContent/ServiceContent";
const Services = () => {
  return (
    <div>
      <ServiceContent />
      <Footer1 />
    </div>
  );
};
export default Services;