import React from "react";
import "./CareerJobs.css";
import "../JobApplyPage/JobForm.js";
import { NavLink } from "react-router-dom";
export default function CareerJobs() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  function role(role) {
    localStorage.setItem("role", role);
  }
  return (
    <div id="careerjobHeight">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <p id="career_sizes">
              Our <span style={{ color: "orange" }}>Career</span>{" "}
            </p>
            <p id="career_banner_p">
              At Szigony, we are always on the lookout for someone who believes
              in putting consumers above everyone and everything else, while
              envisioning growth and pursuing excellence for the years to come.
              Sounds like you?
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <img
              src="assets/images/carrer_icon.png"
              id="image-sizes"
              alt="carrer_icon"
            />
          </div>
        </div>

        <div className="row"></div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <h1 className="main-heading text-center" id="joinSz">
            Join the <span style={{ color: "orange" }}>Szigony</span> Team!
          </h1>

          <h1 className="main-heading ">Why Work With Us?</h1>
          <p>
            <i
              className="fas fa-angle-double-right"
              style={{ marginRight: "5px", color: "red" }}
            ></i>
            Our team works closely with you to ensure end-to-end communication
            and transparency at every step.
          </p>
          <p>
            <i
              className="fas fa-angle-double-right"
              style={{ marginRight: "5px", color: "red" }}
            ></i>
            Our flexible project management approaches and Agile execution model
            ensure that we deliver optimal solutions keeping the constraints of
            time, cost and scope in check while maintaining minimal risk.
          </p>
          <p>
            <i
              className="fas fa-angle-double-right"
              style={{ marginRight: "5px", color: "red" }}
            ></i>
            Our team of experienced architects, designers and developers take
            pride in creating solutions that are a perfect balance of
            innovation, design and functionality.
          </p>
        </div>
      </div>

      <div>
        <div className="container mt-3 " id="bg-colourr">
          <div>
            <h1 className="main-heading text-left mb-3" id="simOpen">
              Similar <span style={{ color: "orange" }}>Openings</span>
            </h1>
          </div>

          <table className="table table-bordered table-striped text-center">
            <thead className="thead-dark text-center">
              <tr>
                <th scope="col"></th>
                <th scope="col" colSpan="3">
                  Job Role
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr>
                <th scope="row">1</th>
                <td colSpan="3">React Js</td>
                <td>
                  <NavLink
                    className="apply_form"
                    id="react_form"
                    to="/Job-Apply"
                    onClick={() => {
                      role("Role - ( React JS )");
                      handleClick();
                    }}
                    role="button"
                  >
                    Apply
                  </NavLink>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td colSpan="3">Node JS</td>
                <td>
                  <NavLink
                    className="apply_form"
                    id="node_form"
                    to="/Job-Apply"
                    onClick={() => {
                      role("Role - ( Node JS )");
                      handleClick();
                    }}
                    role="button"
                  >
                    Apply
                  </NavLink>
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colSpan="3">Angular</td>
                <td>
                  <NavLink
                    className="apply_form"
                    id="angular_form"
                    to="/Job-Apply"
                    onClick={() => {
                      role("Role - ( Angular )");
                      handleClick();
                    }}
                    role="button"
                  >
                    Apply
                  </NavLink>
                </td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td colSpan="3">Python</td>
                <td>
                  <NavLink
                    className="apply_form"
                    id="python_form"
                    to="/Job-Apply"
                    onClick={() => {
                      role("Role - ( Python )");
                      handleClick();
                    }}
                    role="button"
                  >
                    Apply
                  </NavLink>
                </td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td colSpan="3">Java</td>
                <td>
                  <NavLink
                    className="apply_form"
                    id="java_form"
                    to="/Job-Apply"
                    onClick={() => {
                      role("Role - ( Java )");
                      handleClick();
                    }}
                    role="button"
                  >
                    Apply
                  </NavLink>
                </td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td colSpan="3">PHP</td>
                <td>
                  <NavLink
                    className="apply_form"
                    id="php_form"
                    to="/Job-Apply"
                    onClick={() => {
                      role("Role - ( PHP )");
                      handleClick();
                    }}
                    role="button"
                  >
                    Apply
                  </NavLink>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
