import React from "react";
import AadMain from "../../Components/AadPage/AadMain";

export default function AndroidApp() {
  return (
    <div>
      <AadMain />
    </div>
  );
}
