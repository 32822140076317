import React, { useState } from "react";
import axios from "axios";
import "../ContactMain/ContactMain.css";
import Popup from "../Popup/Popup";

function ContactMain() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [send, setSend] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(process.env.REACT_APP_CONTACT_FORM_API, values)
      .then(() => {
        setSend({
          send: true,
        });
      })
      .then(() =>
        setShowPopup({
          showPopup: true,
        })
      )
      .catch(() =>
        setShowPopup({
          showPopup: true,
        })
      );
  };

  const handleNameInputChange = (event) => {
    setValues((values) => ({
      ...values,
      name: event.target.value,
    }));
  };

  const handleEmailInputChange = (event) => {
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };

  const handleSubjectInputChange = (event) => {
    setValues((values) => ({
      ...values,
      subject: event.target.value,
    }));
  };

  const handleMessageInputChange = (event) => {
    setValues((values) => ({
      ...values,
      message: event.target.value,
    }));
  };

  function resetForm() {
    window.location.reload();
  }

  return (
    <div>
      <div className="text-center ">
        <h2 className="main-heading text-center mt-5" id="achieve1">
          Get in
          <span className={{ color: "orange" }}> Touch</span>
        </h2>
      </div>
      <div className="">
        <div className="row ">
          <div className="col-12 col-md-6 bg-dark border" id="col1_details">
            <div className="col-12" id="contact-box">
              <div className="mt-5"></div>
              <br></br>
              <div className="d-flex justify-content-center align-items-center flex-column text-center mt-5">
                <h2 style={{ color: "orange" }}>
                  <i className="fas fa-address-book" id="fa_cont"></i>Venue
                  Address (Bangalore)
                </h2>
                <span style={{ color: "white" }} className="text-center">
                  <p>
                    No 43, Aryan Grand Residency, Byagadadenahalli,
                    Karnataka-562106
                  </p>
                </span>
              </div>
            </div>
            <br></br>
            <br></br>
            <div className="row">
              <div className="col-12 col-md-6 mb-5">
                <div className="d-flex justify-content-center align-items-center flex-column text-center">
                  <h2 style={{ color: "orange" }}>
                    <i className="fas fa-phone" id="fa_cont1"></i>Number
                  </h2>
                  <span>
                    <a href="tel:+91 9686953002" id="lin_dec">
                      +919686953002
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-5">
                <div className="d-flex justify-content-center align-items-center flex-column text-center">
                  <h2 style={{ color: "orange" }}>
                    <i className="fas fa-envelope" id="fa_cont1"></i>Email
                  </h2>
                  <span>
                    <a
                      href="mailto:info@szigonytech.com"
                      className="text-decoration-none"
                      id="lin_dec"
                    >
                      info@szigonytech.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5">
              <iframe
                src="https://maps.google.com/maps?q=No%2043,%20Szigony%20Technologies%20,%20Byagadadenahalli,%20Karnataka-562106&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="row ">
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 bg-dark border"
            id="col1_details"
          >
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 "
              id="contact-box"
            >
              <div className="mt-5"></div>
              <br></br>
              <div className="d-flex justify-content-center align-items-center flex-column  text-center mt-5">
                <h2 style={{ color: "orange" }}>
                  <i className="fas fa-address-book" id="fa_cont"></i>Venue
                  Address (USA)
                </h2>

                <span style={{ Color: "rgb(0, 0, 0)" }} className="text-center">
                  {" "}
                  <p style={{ color: "white" }}>
                    Szigony Technologies LLC 17 Kewaunee Ct Madison,
                    WI53705-4875 United States of America
                  </p>
                </span>
              </div>
            </div>
            <br></br>
            <br></br>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="d-flex justify-content-center align-items-center flex-column  text-center mb-5">
                  <h2 style={{ color: "orange" }}>
                    <i className="fas fa-phone" id="fa_cont1"></i>Number
                  </h2>
                  <span>
                    <a href="tel:+91 9686953002 " id="lin_dec">
                      {" "}
                      +1 (608) 770-0058{" "}
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="d-flex justify-content-center align-items-center flex-column  text-center mb-5 ">
                  <h2 style={{ color: "orange" }}>
                    <i className="fas fa-envelope" id="fa_cont1"></i>Email
                  </h2>
                  <span>
                    <a
                      href="mailto:info@szigonytech.com"
                      className="text-decoration-none"
                      id="lin_dec"
                    >
                      {" "}
                      info@szigonytech.com{" "}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5">
              <iframe
                src="https://maps.google.com/maps?q=17%20Kewaunee%20Ct%20Madison,%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20WI53705-4875%20United%20States%20of%20America&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMain;
