import React from "react";
import "./Devops.css";
import Footer from "../HomePage/Footer/Footer";
import { NavLink } from "react-router-dom";
export default function Devopss() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div id="marg_in">
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <ul id="listt_link">
                <NavLink
                  to="/mobile-app-development"
                  className="a_dec"
                  id="service_1"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Mobile App Development</li>
                </NavLink>
                <NavLink
                  to="/android-app-development"
                  className="a_dec"
                  id="service_2"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Android App Development</li>
                </NavLink>
                <NavLink
                  to="/ios-development"
                  className="a_dec"
                  id="service_3"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">IOS Development</li>
                </NavLink>
                <NavLink
                  to="/flutter-app-development"
                  className="a_dec"
                  id="service_4"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Flutter App Development</li>
                </NavLink>
                <NavLink
                  to="/AI-solution"
                  className="a_dec"
                  id="service_5"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">AI Solution</li>
                </NavLink>
                <NavLink
                  to="/web-development"
                  className="a_dec"
                  id="service_6"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Web Development</li>
                </NavLink>
                <NavLink
                  to="/devops"
                  className="a_dec"
                  id="service_7"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li
                    id="li_des"
                    style={{
                      backgroundImage:
                        "linear-gradient(-45deg, orange 0%, white 100%)",
                      fontWeight: "bold",
                    }}
                  >
                    Devops
                  </li>
                </NavLink>
                <NavLink
                  to="/automation-testing"
                  className="a_dec"
                  id="service_8"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Automation Testing</li>
                </NavLink>
                <NavLink
                  to="/webscraping"
                  className="a_dec"
                  id="service_9"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Web Scrapping</li>
                </NavLink>
              </ul>
            </div>

            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
              <div className="container" id="content_design">
                <h2 id="mad_head">
                  <span style={{ color: "orange", fontSize: "20px" }}>
                    Devops{" "}
                  </span>
                </h2>

                <p style={{ textIndent: "50px" }}>
                  DevOps is a set of automated software practices that combine
                  software development, testing and IT operations that shorten
                  the software development life cycle and provide continuous
                  delivery while aligning with business objectives and
                  maintaining high quality.
                </p>
                <p>
                  Introducing DevOPs into your development lifecycle allows
                  Agile Development Teams to implement Continuous Integration
                  and Continuous Delivery. This helps to launch products faster
                  into the market. This also enables businesses in IT, telecom,
                  retail, manufacturing, healthcare etc. optimize their software
                  development and deployment and maintenance costs.
                </p>
                <p>
                  We at Szigony have deep expertise in implementing DevOP
                  strategies that will fit your business requirements, solve
                  specific challenge areas, drive growth and to optimize the
                  design to deployment pipeline.
                </p>
                <p>
                  Using our DevOp solutions and services we help organizations
                  shift their code deployment cycles to weeks and months instead
                  of years.
                </p>
                <h2 style={{ color: "black" }}>
                  Our DevOps lifecycle implementation solutions
                </h2>

                <div>
                  <ul id="Dev_heig" style={{ marginLeft: "-30px" }}>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Promote deep integration between development and
                      operations
                    </li>

                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Speed up software development and delivery process by
                      separating the development process into small cycles
                    </li>

                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Tools like Selenium enable continuous code integration and
                      continuous testing.
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Enables continuous deployment while supporting high
                      traffic on web portals.
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      While our Agile execution models take care of gaps in
                      customer and developer communication
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Our DevOp methodologies take care of gaps between
                      developer and IT operations.
                    </li>
                  </ul>
                </div>
                <h2 id="border_head">
                  {" "}
                  <span style={{ color: "black" }}>What we offer?</span>
                </h2>
                <ul id="Dev_heig" style={{ marginLeft: "-30px" }}>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Customer-centric solutions
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    End-to-end responsibility of product life cycle
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Continuous Improvement of products and services.
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    A passionate group of DevOps certified a developers,
                    designers and testers that work as one team
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Complete automation services
                  </li>
                  <li>
                    <i
                      className="fas fa-angle-double-right"
                      style={{ marginRight: "5px", color: "red" }}
                    ></i>
                    Robust monitoring and testing procedures.
                  </li>
                </ul>
                <div>
                  <h2 style={{ color: "black" }}>
                    Our team at Szigony have proven expertise on DevOP
                    automation processes such as:
                  </h2>
                  <ul id="Dev_heig" style={{ marginLeft: "-30px" }}>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Infrastructure Automation
                    </li>

                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Configuration Management
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Deployment Automation
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Log management
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Performance Management
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Monitoring
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 style={{ color: "black" }}>
                    {" "}
                    Using tools and frameworks such as
                  </h2>
                  <ul id="Dev_heig" style={{ marginLeft: "-30px" }}>
                    <li>
                      <i
                        className="fas fa-adjust"
                        style={{ color: "red", marginRight: "10px" }}
                      ></i>
                      Amazon Web Services
                    </li>
                    <li>
                      <i
                        className="fas fa-adjust"
                        style={{ color: "red", marginRight: "10px" }}
                      ></i>
                      Chef
                    </li>
                    <li>
                      <i
                        className="fas fa-adjust"
                        style={{ color: "red", marginRight: "10px" }}
                      ></i>
                      Jenkins
                    </li>
                    <li>
                      <i
                        className="fas fa-adjust"
                        style={{ color: "red", marginRight: "10px" }}
                      ></i>
                      Splunk
                    </li>
                    <li>
                      <i
                        className="fas fa-adjust"
                        style={{ color: "red", marginRight: "10px" }}
                      ></i>
                      Nagios
                    </li>
                    <li>
                      <i
                        className="fas fa-adjust"
                        style={{ color: "red", marginRight: "10px" }}
                      ></i>
                      App-dynamic etc.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                    <NavLink
                      to="/service"
                      id="fa_siz_center"
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      All services
                    </NavLink>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
