import React from "react";
import Footer1 from "../../Components/HomePage/Footer1/Footer1";
import JobForm from "../../Components/JobApplyPage/JobForm";
export default function Job() {
  return (
    <div>
      <JobForm />
      <Footer1 />
    </div>
  );
}
