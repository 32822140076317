import React from "react";
import Footer from "../HomePage/Footer/Footer";
import "../WebScrapingPage/WebScrap.css";
import { NavLink } from "react-router-dom";
export default function WebScrap() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div id="heig_scrap">
      <div id="marg_in">
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <ul id="listt_link">
                <NavLink
                  to="/mobile-app-development"
                  className="a_dec"
                  id="service_1"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Mobile App Development</li>
                </NavLink>
                <NavLink
                  to="/android-app-development"
                  className="a_dec"
                  id="service_2"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Android App Development</li>
                </NavLink>
                <NavLink
                  to="/ios-development"
                  className="a_dec"
                  id="service_3"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">IOS Development</li>
                </NavLink>
                <NavLink
                  to="/flutter-app-development"
                  className="a_dec"
                  id="service_4"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Flutter App Development</li>
                </NavLink>
                <NavLink
                  to="/AI-solution"
                  className="a_dec"
                  id="service_5"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">AI Solution</li>
                </NavLink>
                <NavLink
                  to="/web-development"
                  className="a_dec"
                  id="service_6"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Web Development</li>
                </NavLink>
                <NavLink
                  to="/devops/"
                  className="a_dec"
                  id="service_7"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Devops</li>
                </NavLink>
                <NavLink
                  to="/automation-testing"
                  className="a_dec"
                  id="service_8"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Automation Testing</li>
                </NavLink>
                <NavLink
                  to="/webscraping"
                  className="a_dec"
                  id="service_9"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li
                    id="li_des"
                    style={{
                      backgroundImage:
                        "linear-gradient(-45deg, orange 0%, white 100%)",
                      fontWeight: "bold",
                    }}
                  >
                    Web Scrapping
                  </li>
                </NavLink>
              </ul>
            </div>

            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
              <div className="container" id="content_design">
                <h2 id="mad_head">
                  <span style={{ color: "orange", fontSize: "20px" }}>
                    Web{" "}
                  </span>
                  Scrapping
                </h2>

                <p style={{ textIndent: "50px" }}>
                  There are many Data Analytic companies which are in need of
                  data from various sources such as e-commerce websites, social
                  media network and so on. We are specialized in extracting data
                  from unstructured media to structured format, Comparison of
                  data from one competitor with another is made easier with
                  visualization tools, However, the set of inter-comparable data
                  is not in state in which only the data needs to be compared.
                  This state is attributed to lack of Artificial Intelligence in
                  the visualization tools.
                </p>
                <br></br>
                <p>
                  We provide the solution to extract data from various sources
                  and cleanse the same, which is needed to render the
                  multi-source data as “state in which comparison can start”...
                </p>
                <br></br>
                <p>
                  <strong style={{ color: "black" }}>Technologies Used:</strong>{" "}
                  Node.js, Perl, Python, selenium, Redis, RabbitMq and Mysql.
                </p>
                <br></br>
                <p>
                  <strong style={{ color: "black" }}>
                    Ethical Collection Policies:
                  </strong>{" "}
                  We strictly follow General Data Protection Regulation (GDPR)
                  guidelines while collecting data from any external sources.
                </p>

                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                      <NavLink
                        to="/service"
                        id="fa_siz_center"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        All services
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
