import React from "react";
import "../AboutIntro/AboutIntro.css";
import { NavLink } from "react-router-dom";
export default function AboutIntro() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div style={{ backgroundColor: "rgb(245, 247, 255)" }}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <img
              src="assets/images/circleTable.png"
              id="serviceHome-img"
              alt="circleTable"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-lg-5 py-5">
            <h2 className="text-left" id="p2-clients">
              <span style={{ color: "orange" }}>Our</span> Services
            </h2>
            <p>
              At Szigony we aim to be your trusted partner who can take care of
              your technology needs while you focus on the business.
            </p>
            <ul id="WD_ul1">
              <NavLink
                to="/mobile-app-development"
                id="mobilee"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">Mobile App </li>
              </NavLink>
              <NavLink
                to="/android-app-development"
                id="androidd"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">Android App </li>
              </NavLink>
              <NavLink
                to="/flutter-app-development"
                id="flutterr"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">Flutter App </li>
              </NavLink>
              <NavLink
                to="/automation-testing"
                id="autoo"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">Automation Testing </li>
              </NavLink>
              <NavLink
                to="/AI-solution"
                id="aisolution"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">AI Solutions </li>
              </NavLink>
              <NavLink
                to="/devops"
                id="devopss"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">DevOPs</li>
              </NavLink>
              <NavLink
                to="/webscraping"
                id="webscrapingg"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">Web Scraping </li>
              </NavLink>
              <NavLink
                to="/ios-development"
                id="ioss"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">IOS Development</li>
              </NavLink>
              <NavLink
                to="/web-development"
                id="webdevelopmentt"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a1">Web Development</li>
              </NavLink>
              <NavLink
                to="/service"
                id="allservice"
                onClick={() => {
                  handleClick();
                }}
              >
                <li id="a2">All service</li>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
