import React from "react";
import Flutterr from "../../Components/FlutterPage/Flutterr";

export default function Flutter() {
  return (
    <div>
      <Flutterr />
    </div>
  );
}
