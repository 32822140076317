import React from "react";
import "../Footer/Footer.css";
import { NavLink } from "react-router-dom";
const Footer = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer id="footer_service">
        <div className="">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center">
              <NavLink
                to="/"
                className="brand-logo"
                onClick={() => {
                  handleClick();
                }}
              >
                <img
                  id="img-footer2"
                  src="assets/images/logo_white.png"
                  alt="footer_logo"
                />
              </NavLink>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-center">
              <ul className="list-inline ">
                <li className="list-inline-item" id="fo_linkAll1">
                  <NavLink
                    to="/"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Home <span style={{ color: "white" }}>|</span>
                  </NavLink>
                </li>
                <li className="list-inline-item" id="fo_linkAll1">
                  <NavLink
                    to="/about"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    About <span style={{ color: "white" }}>|</span>
                  </NavLink>
                </li>
                <li className="list-inline-item" id="fo_linkAll1">
                  <NavLink
                    to="/service"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Services <span style={{ color: "white" }}>|</span>
                  </NavLink>
                </li>

                <li className="list-inline-item" id="fo_linkAll1">
                  <NavLink
                    to="/career"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Career <span style={{ color: "white" }}>|</span>
                  </NavLink>
                </li>
                <li className="list-inline-item" id="fo_linkAll1">
                  <NavLink
                    to="/contact"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
