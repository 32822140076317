import React from "react";
import "../ServiceContent/ServiceContent.css";
import { NavLink } from "react-router-dom";
export default function ServiceContent() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      id="bg_design_service"
      style={{ backgroundImage: "url('assets/images/bg_Design.jpg')" }}
    >
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle1  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <i className="fas fa-mobile-alt" id="fonaws"></i>
            </div>
            <NavLink
              to="/mobile-app-development"
              id="servicee_mad"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services settings">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> Mobile App Development </h4>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle2  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <i className="fab fa-android" id="fonaws"></i>
            </div>
            <NavLink
              to="/android-app-development"
              id="service_aad"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services speedup">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> Android App Development </h4>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle3  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <i className="fab fa-apple" id="fonaws"></i>
            </div>
            <NavLink
              to="/ios-development"
              id="service_ios"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services privacy">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> IOS Development </h4>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle4  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <img
                src="assets/images/flutterNew.png"
                alt="flutter"
                style={{ width: "60px" }}
              />
            </div>
            <NavLink
              to="/flutter-app-development"
              id="service_flutter"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services backups">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> Flutter app development </h4>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle5  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <h1 id="ai">AI</h1>
            </div>
            <NavLink
              to="/AI-solution"
              id="service_ai"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services ssl">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> AI Solutions</h4>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle6  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <i className="fas fa-laptop-code" id="fonaws"></i>
            </div>
            <NavLink
              to="/web-development"
              id="service_webdev"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services database">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> Web Development </h4>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle7  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <i className="fas fa-infinity" id="fonaws"></i>
            </div>
            <NavLink
              to="/devops"
              id="service_devops"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services speedup">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> DevOPs </h4>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle8  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <i className="fas fa-cogs" id="fonaws"></i>
            </div>
            <NavLink
              to="/automation-testing"
              id="service_auto"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services settings">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> Automation Testing </h4>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-4">
            <div
              id="sam"
              className="md-circle9  border-white d-flex justify-content-center align-items-center mb-md-4 mb-3 flex-shrink-0"
            >
              <i className="fas fa-search" id="fonaws"></i>
            </div>
            <NavLink
              to="/webscraping"
              id="service_web"
              onClick={() => {
                handleClick();
              }}
            >
              <div className="box">
                <div className="our-services backups">
                  <div className="icon">
                    {" "}
                    <img src="" />{" "}
                  </div>
                  <h4 id="headd"> Web-Scraping </h4>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
