import React from "react";
import "../AboutContact/AboutContact.css";
import { NavLink } from "react-router-dom";
export default function AboutContact() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="con-full">
      <div className="">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
          <div
            className=""
            id="bg_img_idea"
            style={{ backgroundImage: "url('assets/images/idea-1.png')" }}
          >
            <div className="">
              <div className="">
                <p style={{ fontStyle: "italic" }}>
                  Have an idea? We are listening.
                </p>
              </div>
              <NavLink
                className="btn btn-warning text-capitalize mt-2"
                to="/contact#achieve1"
                role="button"
                onClick={() => {
                  handleClick();
                }}
              >
                Reach Us
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
