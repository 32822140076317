import React from "react";
import Languages from "../../Components/AboutPage/Languages/Languages";
import CareerJobs from "../../Components/CareerPage/CareerJobs";
import Footer1 from "../../Components/HomePage/Footer1/Footer1";
const Career = () => {
  return (
    <div>
      <CareerJobs />
      <Languages />
      <Footer1 />
    </div>
  );
};
export default Career;
