import React from "react";
import Footer from "../HomePage/Footer/Footer";
import "../IOSPage/Ios.css";
import { NavLink } from "react-router-dom";
export default function IOS() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <>
        <div id="marg_in">
          <div className="container">
            <div className="row">
              <div className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <ul id="listt_link">
                  <NavLink
                    to="/mobile-app-development"
                    className="a_dec"
                    id="service_1"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Mobile App Development</li>
                  </NavLink>
                  <NavLink
                    to="/android-app-development"
                    className="a_dec"
                    id="service_2"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Android App Development</li>
                  </NavLink>
                  <NavLink
                    to="/ios-development"
                    className="a_dec"
                    id="service_3"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li
                      id="li_des"
                      style={{
                        backgroundImage:
                          "linear-gradient(-45deg, orange 0%, white 100%)",
                        fontWeight: "bold",
                      }}
                    >
                      IOS Development
                    </li>
                  </NavLink>
                  <NavLink
                    to="/flutter-app-development"
                    className="a_dec"
                    id="service_4"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Flutter App Development</li>
                  </NavLink>
                  <NavLink
                    to="/AI-solution"
                    className="a_dec"
                    id="service_5"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">AI Solution</li>
                  </NavLink>
                  <NavLink
                    to="/web-development"
                    className="a_dec"
                    id="service_6"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Web Development</li>
                  </NavLink>
                  <NavLink
                    to="/devops/"
                    className="a_dec"
                    id="service_7"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Devops</li>
                  </NavLink>
                  <NavLink
                    to="/automation-testing"
                    className="a_dec"
                    id="service_8"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Automation Testing</li>
                  </NavLink>
                  <NavLink
                    to="/webscraping"
                    className="a_dec"
                    id="service_9"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Web Scrapping</li>
                  </NavLink>
                </ul>
              </div>

              <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                <div className="container" id="content_design">
                  <h2 id="mad_head">
                    <span style={{ color: "orange", fontSize: "20px" }}>
                      IOS{" "}
                    </span>
                    Development
                  </h2>

                  <p style={{ textIndent: "50px" }}>
                    When it comes to the mobile phones, Apple iPhones are a
                    class apart esp concerning their design, usability and
                    security. Its no wonder that Apple is the industry leader in
                    smartphone manufacturers esp in the United States and Europe
                    with a rapid customer base building up in China and other
                    countries selling more than 200 million iPhones every year.
                    The iOS Operating system is a unique platform that is the
                    real differentiating factor behind the popularity of Apple
                    products such as iPhones and iPods. Studies indicate that an
                    iPhone user is more likely to spend on premium app services
                    and products. Building the right iOS app with intuitive
                    UI/UX design and robust functionality can be a game-changer
                    for your business idea to take wings and establish your
                    brand image as a premium product.
                  </p>
                  <p>
                    At Szigony we are a team of skilled iOS/iPhone App
                    developers with proven expertise in building bespoke Apps
                    that can drive businesses to great heights. Our focus is
                    always on meeting customer requirements and exceeding
                    expectations by providing a seamless service and high-
                    performance deliverables.
                  </p>

                  <h2 id="border_head">
                    {" "}
                    <span style={{ color: "black" }}>Why Choose Us?</span>
                  </h2>
                  <div>
                    <ul style={{ marginLeft: "-30px" }}>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        We provide end-to-end solutions that cover all aspects
                        of mobile application development lifecycle
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        A strong focus on ensuring the Quality of all our
                        deliverables
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        Rapid design,implementation, integration and deployment
                        of apps and solutions using Agile processes and DevOP
                        methodologies
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        A skilled team with an in-depth understanding of Apple
                        design principles and guidelines with strong knowledge
                        of OOPs, Swift and Apple iOS lifecycle.
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        Focus on building Smart solutions that are compatible
                        across a range of iPhone models and iOS versions
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                      <NavLink
                        to="/service"
                        id="fa_siz_center"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        All services
                      </NavLink>
                    </div>
                  </div>
                </div>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
