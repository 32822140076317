import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Services from "./pages/Service/Services";
import Career from "./pages/Carrer/Career";
import MobileAppDevelopment from "./pages/MobileAppDevelopment/MobileAppDevelopment";
import AndroidApp from "./pages/AndroidAppDevelopment/AndroidApp";
import Devops from "./pages/Devops/Devops";
import Ios from "./pages/IosDevelopment/Ios";
import Ai from "./pages/AI/Ai";
import Automation from "./pages/Automation/Automation";
import WebDevelopment from "./pages/WebDevelopment/WebDevelopment";
import WebScrapping from "./pages/webScrapping/WebScrapping";
import Navbar1 from "./Components/HomePage/Navbar1/Navbar1";
import ScrollToTop from "react-scroll-to-top";
import Job from "./pages/Job/Job";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import Flutter from "./pages/FlutterApp/Flutter";

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <>
      <ScrollToTop
        smooth
        color="white"
        height="20px "
        style={{
          background: "#121f5e",
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        }}
      />

      <Router>
        <Navbar1 />
        <main>
          <Switch>
            <Route path="/home" exact={true} component={Home}></Route>
            <Route path="/about" exact={true} component={About}></Route>
            <Route path="/service" exact={true} component={Services}></Route>
            <Route path="/career" exact={true} component={Career}></Route>
            <Route path="/contact" exact={true} component={Contact}></Route>
            <Route
              path="/mobile-app-development"
              exact={true}
              component={MobileAppDevelopment}
            ></Route>
            <Route
              path="/android-app-development"
              exact={true}
              component={AndroidApp}
            ></Route>
            <Route path="/ios-development" exact={true} component={Ios}></Route>
            <Route
              path="/flutter-app-development"
              exact={true}
              component={Flutter}
            ></Route>
            <Route path="/AI-solution" exact={true} component={Ai}></Route>
            <Route
              path="/web-development"
              exact={true}
              component={WebDevelopment}
            ></Route>
            <Route path="/devops" exact={true} component={Devops}></Route>
            <Route
              path="/automation-testing"
              exact={true}
              component={Automation}
            ></Route>
            <Route
              path="/webscraping"
              exact={true}
              component={WebScrapping}
            ></Route>
            <Route path="/job-apply" exact={true} component={Job}></Route>
            <Route
              path="/terms-and-condition"
              exact={true}
              component={TermsCondition}
            ></Route>
            <Route
              path="/privacy-policy"
              exact={true}
              component={PrivacyPolicy}
            ></Route>
            <Redirect to="/home" />
          </Switch>
        </main>
      </Router>
    </>
  );
};

export default App;
