import React from "react";
import "./Popup.css";

export default function Popup({
  msg,
  msgSuccess,
  setMsgSuccess,
  setShowPopup,
  resetForm,
}) {
  const handleClose = () => {
    resetForm();
    setMsgSuccess(false);
    setShowPopup(false);
  };

  return (
    <>
      <div className="popup-overlay"></div>
      <div className="popup">
        <div className="popup-content">
          {msgSuccess ? (
            <>
              {" "}
              <span className="popup-msg">
                Your application has been sent successfully. We'll reach you
                shortly.
              </span>
              <div>
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn btn-success"
                  id="btn_close_form"
                >
                  Ok
                </button>
              </div>
            </>
          ) : (
            <>
              {" "}
              <span className="popup-msg">{msg}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
}
