import React from "react";
import "../AadPage/AadMain.css";
import Footer from "../HomePage/Footer/Footer";
import { NavLink } from "react-router-dom";
export default function AadMain() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div id="marg_in">
        <div className="container">
          <div className="row">
            <div
              className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
              id="height_fixed"
            >
              <ul id="listt_link">
                <NavLink
                  to="/mobile-app-development"
                  className="a_dec"
                  id="service_1"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Mobile App Development</li>
                </NavLink>
                <NavLink
                  to="/android-app-development"
                  className="a_dec"
                  id="service_2"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li
                    id="li_des"
                    style={{
                      backgroundImage:
                        "linear-gradient(-45deg, orange 0%, white 100%)",
                      fontWeight: "bold",
                    }}
                  >
                    Android App Development
                  </li>
                </NavLink>
                <NavLink
                  to="/ios-development"
                  className="a_dec"
                  id="service_3"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">IOS Development</li>
                </NavLink>
                <NavLink
                  to="/flutter-app-development"
                  className="a_dec"
                  id="service_4"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Flutter App Development</li>
                </NavLink>
                <NavLink
                  to="/AI-solution"
                  className="a_dec"
                  id="service_5"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">AI Solution</li>
                </NavLink>
                <NavLink
                  to="/web-development"
                  className="a_dec"
                  id="service_6"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Web Development</li>
                </NavLink>
                <NavLink
                  to="/devops"
                  className="a_dec"
                  id="service_7"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Devops</li>
                </NavLink>
                <NavLink
                  to="/automation-testing"
                  className="a_dec"
                  id="service_8"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  <li id="li_des">Automation Testing</li>
                </NavLink>
                <NavLink
                  to="/webscraping"
                  className="a_dec"
                  id="service_9"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {" "}
                  <li id="li_des">Web Scrapping</li>
                </NavLink>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
              <div className="container" id="content_design">
                <h2 id="mad_head">
                  <span style={{ color: "orange", fontSize: "20px" }}>
                    Android{" "}
                  </span>
                  App Development
                </h2>
                <p style={{ textIndent: "50px" }}>
                  As of 2020,there are more than 3 billion Android smartphone
                  users in the world with the number expected to rise
                  exponentially in the coming years. Szigony is an emerging
                  android app development company based in Bangalore India that
                  specializes in building high-performance android apps with
                  strong backend architecture and appealing UI designs that will
                  exceed your expectations.
                </p>
                <h2 id="border_head">
                  {" "}
                  <span style={{ color: "black" }}>What we offer?</span>
                </h2>
                <div>
                  <ul style={{ marginLeft: "-30px" }}>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Custom made designs
                    </li>
                    <li>
                      Implementation of Agile Processes that shorten the gap
                      between ideation to product delivery timeline
                    </li>
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Proven expertise in the entire app design lifecycle, from
                      custom interface design to robust back-end architecture
                    </li>
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      A strong technical team with expertise in Android Studio,
                      GitHub, Jenkins and Jira.
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Robust QA processes.
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      On-time Delivery
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Solutions that suit your budget.
                    </li>
                  </ul>
                  <h2 style={{ color: "black" }}>
                    Types of Android App Services offered by us:
                  </h2>
                  <ul style={{ marginLeft: "-30px" }}>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Customised App Solutions
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Enterprise Mobile Applications
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Installation, Upgrade and Migration Services
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Application Support and Maintenance
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Application Testing and QA
                    </li>{" "}
                    <br></br>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Cloud-Based Solutions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                    <NavLink
                      to="/service"
                      id="fa_siz_center"
                      onClick={() => {
                        handleClick();
                      }}
                    >
                      All services
                    </NavLink>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
