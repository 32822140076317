import React from "react";
import Footer1 from "../../Components/HomePage/Footer1/Footer1";
import TermsAndCondition from "../../Components/TermsAndConditions/TermsAndCondition";

export default function TermsCondition() {
  return (
    <div>
      <TermsAndCondition />
      <Footer1 />
    </div>
  );
}
