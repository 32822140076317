import React from "react";
import AboutContact from "../../Components/AboutPage/AboutContact/AboutContact";
import AboutContent1 from "../../Components/AboutPage/AboutContent1/AboutContent1";
import BannerAbout from "../../Components/AboutPage/BannerAbout/BannerAbout";
import DrivingFactors from "../../Components/AboutPage/DrivingFactors/DrivingFactors";
import Footer1 from "../../Components/HomePage/Footer1/Footer1";
import Design from "../../Components/HomePage/Design/Design";
import WhatWeOffer from "../../Components/AboutPage/WhatWeOffer/WhatWeOffer";

const About = () => {
  return (
    <div>
      <BannerAbout />
      <AboutContent1 />
      <Design />
      <DrivingFactors />
      <WhatWeOffer />
      <AboutContact />
      <Footer1 />
    </div>
  );
};
export default About;