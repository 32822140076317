import React from "react";
import "../Banner1/Banner1.css";
import ParticleConfig from "../../config/ParticleConfig";
import { NavLink } from "react-router-dom";
export default function Banner() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div
        id="ban_img"
        style={{
          backgroundImage: `url("assets/images/banner.png")`,
        }}
      >
        <div style={{ height: "100vh" }}>
          <div className="centered">
            <span style={{ color: "orange" }}>Szigony</span> in Hungarian stands
            for Tridend
          </div>
          <div className="subTitle1">Trusted Solution Partner</div>

          <ParticleConfig />
        </div>

        <NavLink
          id="but_ban"
          className="btn btn-sm"
          to="/contact#achieve1"
          role="button"
          onClick={() => {
            handleClick();
          }}
        >
          Reach Us
        </NavLink>
      </div>
    </>
  );
}
