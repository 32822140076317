import React, { useState } from "react";
import "../JobApplyPage/JobForm.css";
import axios from "axios";
import Popup from "../ContactPage/Popup/Popup";

function JobForm() {
  const [data, setData] = useState({
    Fname: "",
    Lname: "",
    Role: localStorage.getItem("role"),
    Number: "",
    Email: "",
    Address: "",
    Resume: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);

  const handleFNameInputChange = (event) => {
    setData(
      (data) => ({
        ...data,
        Fname: event.target.value,
      }),
      (document.getElementById("buut").disabled = false)
    );
  };

  const handleLNameInputChange = (event) => {
    setData(
      (data) => ({
        ...data,
        Lname: event.target.value,
      }),
      (document.getElementById("buut").disabled = false)
    );
  };

  const handleRoleChange = (event) => {
    setData(
      (data) => ({
        ...data,
        Role: event.target.value,
      }),
      (document.getElementById("buut").disabled = false)
    );
  };

  const handleNumberChange = (event) => {
    setData(
      (data) => ({
        ...data,
        Number: event.target.value,
      }),
      (document.getElementById("buut").disabled = false)
    );
  };

  const handleEmailChange = (event) => {
    setData(
      (data) => ({
        ...data,
        Email: event.target.value,
      }),
      (document.getElementById("buut").disabled = false)
    );
  };

  const handleAddressChange = (event) => {
    setData(
      (data) => ({
        ...data,
        Address: event.target.value,
      }),
      (document.getElementById("buut").disabled = false)
    );
  };

  const onFileChange = (event) => {
    setData((data) => ({
      ...data,
      File: event.target.files[0],
    }));
    var maxfilesize = 1024 * 1024, // 1 Mb
      filesize = event.target.files[0].size,
      warning = document.getElementById("lbError");

    if (filesize > maxfilesize) {
      warning.innerHTML = "File too large " + ". Maximum size: 1 MB ";
      document.getElementById("buut").disabled = true;
      return false;
    } else {
      warning.innerHTML = "";
      document.getElementById("buut").disabled = false;
      return true;
    }
  };

  function resetForm() {
    window.location.reload();
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setShowPopup({
      showPopup: true,
    });
    const headers = {
      "content-type": "multipart/form-data",
    };

    axios
      .post(process.env.REACT_APP_JOB_FORM_API, data, {
        headers,
      })

      .then(() => {
        setMsgSuccess(true);
      })

      .catch(() => setMsgSuccess(true));
  };

  return (
    <div
      id="job_top1"
      style={{ backgroundImage: "url('assets/images/jobpagebanner.jpg')" }}
    >
      <div className="container-xxl">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
            <form
              className="container"
              id="job_form_des"
              onSubmit={handleFormSubmit}
            >
              <div className=" row mb-4">
                <p id="job_headding1">
                  <span id="job_headding2">Job</span> Form
                </p>

                <div className="col">
                  <div className="form-outline">
                    <input
                      type="text"
                      id="form6Example1"
                      placeholder="First Name"
                      className="form-control"
                      required
                      name="fname"
                      value={data.Fname}
                      onChange={handleFNameInputChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <input
                      type="text"
                      id="form6Example2"
                      placeholder="Last Name"
                      className="form-control"
                      required
                      name="lname"
                      value={data.Lname}
                      onChange={handleLNameInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className=" row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <input
                      style={{ color: "gray", fontWeight: "bold" }}
                      type="text"
                      id="jobRole"
                      value={localStorage.getItem("role")}
                      disabled
                      className="form-control"
                      onChange={handleRoleChange}
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="form-outline">
                    <input
                      type="tel"
                      id="form6Example6"
                      required
                      placeholder="Mobile Number"
                      className="form-control"
                      value={data.Number}
                      onChange={handleNumberChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-outline mb-4">
                <input
                  type="email"
                  id="form6Example5"
                  placeholder="Email"
                  className="form-control"
                  name="email"
                  required
                  value={data.Email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="form-outline mb-4">
                <textarea
                  rows="2"
                  id="form6Example4"
                  placeholder="Address"
                  className="form-control"
                  name="address"
                  required
                  value={data.Address}
                  onChange={handleAddressChange}
                />
              </div>

              <div className=" row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label>Upload Resume </label>
                    <input
                      type="file"
                      id="Resume"
                      onChange={onFileChange}
                      name="Resume"
                      required
                      accept=".jpg, .png, .jpeg, .pdf, .doc, .docx"
                    />
                    <div id="lbError" class="warning"></div>
                    <br></br>
                    <div id="file_des">
                      *Choose ({" "}
                      <span style={{ color: "black" }}>
                        .pdf, .jpg, .png, .jpeg, .doc, .docx
                      </span>{" "}
                      )
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="form-outline">
                    <button
                      id="buut"
                      type="submit"
                      className="btn btn-warning btn-block btn-lg mt-5"
                      disabled
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </form>

            {/* popup open after submitting form */}

            {showPopup ? (
              <Popup
                msg="Please Wait..."
                msgSuccess={msgSuccess}
                resetForm={resetForm}
                setMsgSuccess={setMsgSuccess}
                setShowPopup={setShowPopup}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobForm;
