import React, { useEffect, useState } from "react";
import "../Navbar1/Navbar1.css";
import $ from "jquery";
import { NavLink } from "react-router-dom";
export default function Navbar1() {
  $(function () {
    $(document).click(function (event) {
      var clickover = $(event.target);
      var _opened = $(".navbar-collapse").hasClass(
        "navbar-collapse collapse show"
      );
      if (_opened === true && !clickover.hasClass("navbar-toggler")) {
        $("button.navbar-toggler").click();
      }
    });
  });

  const handleClick = (id) => {
    window.scrollTo(0, 0);
  };
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light ">
      <NavLink
        className="navbar-brand"
        id="main_logo"
        to="/home"
        onClick={() => {
          handleClick();
        }}
      >
        <img src="assets/images/logo_white.png" alt="logo" />
      </NavLink>

      <button
        className="navbar-toggler navbar-toggler-right collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="close">
          <i className="fas fa-times"></i>
        </span>
        <span id="icon_color1" className="open">
          <i className="fas fa-bars"></i>
        </span>
      </button>
      <div className="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul className="nav   navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink
              className="nav-link"
              activeClassName="active"
              id="home_nav"
              to="/home"
              smooth
              onClick={() => {
                handleClick("home_nav");
              }}
            >
              Home
            </NavLink>
          </li>

          <li className="nav-item ">
            <NavLink
              className="nav-link"
              to="/about"
              smooth
              activeClassName="active"
              id="about_nav"
              onClick={() => {
                handleClick("about_nav");
              }}
            >
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/service"
              smooth
              id="service_nav"
              activeClassName="active"
              onClick={() => {
                handleClick("service_nav");
              }}
            >
              Service
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/career"
              smooth
              id="career_nav"
              activeClassName="active"
              onClick={() => {
                handleClick("career_nav");
              }}
            >
              Career
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/contact"
              smooth
              id="contact_nav"
              activeClassName="active"
              onClick={() => {
                handleClick("contact_nav");
              }}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
