import React from "react";
import "../Footer1/Footer1.css";
import { NavLink } from "react-router-dom";
export default function Footer1() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
              <NavLink
                to="/"
                className="brand-logo"
                onClick={() => {
                  handleClick();
                }}
              >
                <img
                  id="img-footer"
                  src="assets/images/logo_white.png"
                  alt="footer_logo"
                />
              </NavLink>

              <ul className="list-inline ">
                <li className="list-inline-item" id="fo_linkAll">
                  <NavLink
                    to="/"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Home{" "}
                    <span style={{ color: "white", marginLeft: "3px" }}>|</span>
                  </NavLink>
                </li>
                <li className="list-inline-item" id="fo_linkAll">
                  <NavLink
                    to="/about"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    About{" "}
                    <span style={{ color: "white", marginLeft: "3px" }}>|</span>
                  </NavLink>
                </li>
                <li className="list-inline-item" id="fo_linkAll">
                  <NavLink
                    to="/service"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Services{" "}
                    <span style={{ color: "white", marginLeft: "3px" }}>|</span>
                  </NavLink>
                </li>

                <li className="list-inline-item" id="fo_linkAll">
                  <NavLink
                    to="/career"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Career{" "}
                    <span style={{ color: "white", marginLeft: "3px" }}>|</span>
                  </NavLink>
                </li>
                <li className="list-inline-item" id="fo_linkAll">
                  <NavLink
                    to="/contact"
                    id="fo_link"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <div className="social-icons mt-3">
                <a
                  href="https://www.facebook.com/Szigony-Technologies-Pvt-Ltd-357914624752800/?ref=br_tf"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-2"
                >
                  <i id="icon_color" className="fab fa-facebook-f"></i>
                </a>

                <a
                  href="https://twitter.com/SzigonyT/status/1062692688808431616"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-2"
                >
                  <i id="icon_color" className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/szigony-technologies/"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-2"
                >
                  <i id="icon_color" className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pt-2 pb-2 mt-4 border-top">
          <p className="mb-0" id="copyright">
            Copyright © 2022 Szigonytech. All rights reserved.
          </p>

          <NavLink
            to="/terms-and-condition"
            id="copyright"
            onClick={() => {
              handleClick();
            }}
          >
            Terms and Condition <span style={{ marginRight: "3px" }}>|</span>{" "}
          </NavLink>
          <NavLink
            to="/privacy-policy"
            id="copyright"
            onClick={() => {
              handleClick();
            }}
          >
            Privacy Policy
          </NavLink>
        </div>
      </footer>
    </div>
  );
}
