import React from "react";
import "../Languages/Languages.css";
function Languages() {
  return (
    <div className="container">
      <div className="text-center  mb-5">
        <h1 id="our-tech">
          <span style={{ color: "orange" }}>Our</span> Technologies{" "}
        </h1>
      </div>
      <div className="design_lang">
        <div className="row text-center">
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5 mt-5">
            <img id="lan-img" src="/assets/images/1.png" alt="redis" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5 mt-5">
            <img id="lan-img" src="/assets/images/2.png" alt="python" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5 mt-5">
            <img id="lan-img" src="/assets/images/3.png" alt="node" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5 mt-5">
            <img id="lan-img" src="/assets/images/4.png" alt="jenkins" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/5.png" alt="kubernates" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/6.png" alt="aws" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/7.png" alt="doc" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/8.png" alt="magento" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/9.png" alt="react" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/Redux10.png" alt="redux" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/11.png" alt="php" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/12.png" alt="bitbucket" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/13.png" alt="github" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/14.png" alt="flutter" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img id="lan-img" src="/assets/images/15.png" alt="oracle" />
          </div>
          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-5">
            <img
              id="lan-img"
              src="/assets/images/selenium_icon.png"
              alt="selenium"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Languages;
