import  React, { useState , useEffect } from 'react'

function DateUpdate() {
    var [date,setDate] = useState(new Date());
    
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });
  return (
    <div>
 <h3> Date : {date.toLocaleDateString()}</h3>
    </div>
  )
}

export default DateUpdate