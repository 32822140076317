import React from "react";
import "../Clients/Clients.css";
export default function Clients() {
  return (
    <div id="client-full">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"></div>
        </div>
      </div>

      <h2 className="text-center" id="p-clients">
        Our <span style={{ color: "orange" }}>Clients</span>
      </h2>
      <div id="client">
        <div className="container">
          <div className="row">
            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a
                href="https://www.sap.com/india/index.html"
                target="_blank"
                rel="noreferrer"
              >
                <img src="assets/images/SAP_Logo.png" id="img10" alt="sap" />
              </a>
            </div>
            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a
                href="https://www.pharmapodhq.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="assets/images/pharmapod-logo.png"
                  id="img10"
                  alt="pharmapod"
                />
              </a>
            </div>

            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a
                href="https://www.nttdata.com/global/en/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="assets/images/NTT-Data-Logo.svg"
                  id="img11"
                  alt="nttdata"
                />
              </a>
            </div>
            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a href="https://www.bosch.com/" target="_blank" rel="noreferrer">
                <img src="assets/images/Bosch-Logo.png" id="img10" alt="bosh" />
              </a>
            </div>
            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a
                href="http://www.laderatechnology.com/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src="assets/images/Ladera_Logo-white.png"
                  id="img15"
                  alt="laderatechnology"
                />
              </a>
            </div>

            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a
                href="https://www.yaanetechnologies.com/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src="assets/images/our-clients6.png"
                  id="img10"
                  alt="yaane"
                />
              </a>
            </div>
            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a
                href="http://limitscale.digital/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src="assets/images/our-client5.png"
                  id="img12"
                  alt="limitscale"
                />
              </a>
            </div>

            <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 text-center">
              <a href="https://govansys.com/" target="_blank" rel="noreferrer">
                {" "}
                <img
                  src="assets/images/Govan_icon.webp"
                  id="img10"
                  alt="govansys"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
