import React from "react";
import Footer from "../HomePage/Footer/Footer";
import "../WebDevelopment_page/WebDev.css";
import { NavLink } from "react-router-dom";
export default function WebDev() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <>
        <div id="marg_in">
          <div className="container">
            <div className="row">
              <div className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <ul id="listt_link">
                  <NavLink
                    to="/mobile-app-development"
                    className="a_dec"
                    id="service_1"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Mobile App Development</li>
                  </NavLink>
                  <NavLink
                    to="/android-app-development"
                    className="a_dec"
                    id="service_2"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Android App Development</li>
                  </NavLink>
                  <NavLink
                    to="/ios-development"
                    className="a_dec"
                    id="service_3"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">IOS Development</li>
                  </NavLink>
                  <NavLink
                    to="/flutter-app-development"
                    className="a_dec"
                    id="service_4"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Flutter App Development</li>
                  </NavLink>
                  <NavLink
                    to="/AI-solution"
                    className="a_dec"
                    id="service_5"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">AI Solution</li>
                  </NavLink>
                  <NavLink
                    to="/web-development"
                    className="a_dec"
                    id="service_6"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li
                      id="li_des"
                      style={{
                        backgroundImage:
                          "linear-gradient(-45deg, orange 0%, white 100%)",
                        fontWeight: "bold",
                      }}
                    >
                      Web Development
                    </li>
                  </NavLink>
                  <NavLink
                    to="/devops"
                    className="a_dec"
                    id="service_7"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Devops</li>
                  </NavLink>
                  <NavLink
                    to="/automation-testing"
                    className="a_dec"
                    id="service_8"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Automation Testing</li>
                  </NavLink>
                  <NavLink
                    to="/webscraping"
                    className="a_dec"
                    id="service_9"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Web Scrapping</li>
                  </NavLink>
                </ul>
              </div>

              <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                <div className="container" id="content_design">
                  <h2 id="mad_head">
                    <span style={{ color: "orange", fontSize: "20px" }}>
                      Web{" "}
                    </span>
                    Development
                  </h2>

                  <p style={{ textIndent: "50px" }}>
                    The pandemic has forced most businesses today from
                    e-commerce to entertainment, to pivot their business
                    operations to a completely online mode. Today giving clients
                    a seamless online experience as well as collecting customer
                    demographics via their digital footprint is not only a
                    requirement but a huge driver for businesses to survive and
                    thrive in the market.
                  </p>
                  <p>
                    We at Szigony are experts at delivering end-to-end solutions
                    that will serve all your Web development needs. Our services
                    include custom website development, web design, web
                    programming, database management, web apps, UI/UX design and
                    more.
                  </p>
                  <p>
                    We are an emerging web development company in Bangalore
                    India and our team of web development experts can help build
                    highly customised web solutions that will make your brand
                    stand out,and generate high traffic, hits and leads helping
                    you drive your business to new levels.
                  </p>
                  <p>
                    Whether you are a start-up, a mid-level enterprise or an
                    established brand, we can build highly customised solutions
                    for your exact needs. Our team of skilled developers and
                    designers are experts in web design frameworks and
                    technology stacks such as
                  </p>

                  <div>
                    <ul id="WD_ul" style={{ marginLeft: "-30px" }}>
                      <li>
                        {" "}
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        PHP
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        Redis
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        Python
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        AWS
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        Node
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        React
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        GitHub
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        HTML and CSS
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        Flutter
                      </li>
                      <li>
                        <i
                          className="fas fa-adjust fa-spin  "
                          style={{ color: "red", padding: "5px" }}
                        ></i>
                        Docker
                      </li>
                    </ul>
                  </div>
                  <h2 id="border_head">
                    {" "}
                    <span style={{ color: "black" }}>What we offer?</span>
                  </h2>
                  <ul id="uL" style={{ marginLeft: "-30px" }}>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Systematic Approach{" "}
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Complete transparency
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Flexible engagement model
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Passion to build a robust digital presence for you and
                      your company
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      An Agile approach for Faster execution and timely delivery
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Proven track record
                    </li>
                    <li>
                      <i
                        className="fas fa-angle-double-right"
                        style={{ marginRight: "5px", color: "red" }}
                      ></i>
                      Robust quality checks and processes
                    </li>
                  </ul>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                      <NavLink to="/service" id="fa_siz_center">
                        All services
                      </NavLink>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
