import React from "react";
import "../AISolutionPage/AISolution.css";
import Footer from "../HomePage/Footer/Footer";
import { NavLink } from "react-router-dom";
export default function AISolution() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <>
        <div id="marg_in">
          <div className="container">
            <div className="row">
              <div className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <ul id="listt_link">
                  <NavLink
                    to="/mobile-app-development"
                    className="a_dec"
                    id="service_1"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Mobile App Development</li>
                  </NavLink>
                  <NavLink
                    to="/android-app-development"
                    className="a_dec"
                    id="service_2"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Android App Development</li>
                  </NavLink>
                  <NavLink
                    to="/ios-development"
                    className="a_dec"
                    id="service_3"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">IOS Development</li>
                  </NavLink>
                  <NavLink
                    to="/flutter-app-development"
                    className="a_dec"
                    id="service_4"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Flutter App Development</li>
                  </NavLink>
                  <NavLink
                    to="/AI-solution"
                    className="a_dec"
                    id="service_5"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li
                      id="li_des"
                      style={{
                        backgroundImage:
                          "linear-gradient(-45deg, orange 0%, white 100%)",
                        fontWeight: "bold",
                      }}
                    >
                      AI Solution
                    </li>
                  </NavLink>
                  <NavLink
                    to="/web-development"
                    className="a_dec"
                    id="service_6"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Web Development</li>
                  </NavLink>
                  <NavLink
                    to="/devops/"
                    className="a_dec"
                    id="service_7"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Devops</li>
                  </NavLink>
                  <NavLink
                    to="/automation-testing"
                    className="a_dec"
                    id="service_8"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Automation Testing</li>
                  </NavLink>
                  <NavLink
                    to="/webscraping"
                    className="a_dec"
                    id="service_9"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Web Scrapping</li>
                  </NavLink>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                <div className="container" id="content_design">
                  <h2 id="mad_head">
                    <span style={{ color: "orange", fontSize: "20px" }}>
                      AI{" "}
                    </span>
                    Solutions
                  </h2>
                  <h2 id="border_head">
                    {" "}
                    <span>What is AI?</span>
                  </h2>
                  <p>
                    AI technology is all-pervasive today. Everything from
                    intelligent search engines like Alexa and Siri to chatbots
                    to robotics and AR/VR gaming consoles run on AI. In a
                    competitive world of enterprise mobility solutions, AI can
                    be the much-needed game-changer for your product that can
                    transform end-user experience, elevating your business brand
                    perception and making your brand stand out.
                  </p>
                  <h2 id="border_head">
                    {" "}
                    <span>Why Us?</span>
                  </h2>
                  <p>
                    We at Szigony are an emerging AI Development Solutions
                    provider building highly immersive AI integrated solutions
                    that enhance human potential, by reducing decision-making
                    processes, eliminating human error and redundancy, and
                    aiding complex data processing and analysis.
                  </p>
                  <p>
                    Our team of highly skilled programmers and AI developers
                    have the competence to develop powerful AI powered products
                    for a wide range of industries from Healthcare to Gaming and
                    technology.
                  </p>
                  <div>
                    <h2 style={{ color: "black" }}>Our expertise includes</h2>
                    <ul style={{ marginLeft: "-30px" }}>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        AI Consulting: We offer bespoke AI consulting services
                        that can help you identify how AI will fit into your
                        business model helping you reinvent your market reach.
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        Machine Language: Use of Machine language algorithms
                        help us build smart products for businesses that use
                        acquired data to give intelligent solutions and aid
                        decision making processes
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        Natural Language Processing: Our NLP driven tools and
                        services enable businesses to do in depth analysis of
                        market trends by accurately extracting information and
                        insights from social media and web apps
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                      <NavLink
                        to="/service"
                        id="fa_siz_center"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        All services
                      </NavLink>
                    </div>
                  </div>
                </div>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
