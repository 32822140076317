import React from "react";
import "../FlutterPage/Flutter.css";
import Footer from "../HomePage/Footer/Footer";
import { NavLink } from "react-router-dom";
export default function Flutterr() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <>
        <div id="marg_in">
          <div className="container">
            <div className="row">
              <div className="d-none d-md-block col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                <ul id="listt_link">
                  <NavLink
                    to="/mobile-app-development"
                    className="a_dec"
                    id="service_1"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Mobile App Development</li>
                  </NavLink>
                  <NavLink
                    to="/android-app-development"
                    className="a_dec"
                    id="service_2"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Android App Development</li>
                  </NavLink>
                  <NavLink
                    to="/ios-development"
                    className="a_dec"
                    id="service_3"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">IOS Development</li>
                  </NavLink>
                  <NavLink
                    to="/flutter-app-development"
                    className="a_dec"
                    id="service_4"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li
                      id="li_des"
                      style={{
                        backgroundImage:
                          "linear-gradient(-45deg, orange 0%, white 100%)",
                        fontWeight: "bold",
                      }}
                    >
                      Flutter App Development
                    </li>
                  </NavLink>
                  <NavLink
                    to="/AI-solution"
                    className="a_dec"
                    id="service_5"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">AI Solution</li>
                  </NavLink>
                  <NavLink
                    to="/web-development"
                    className="a_dec"
                    id="service_6"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Web Development</li>
                  </NavLink>
                  <NavLink
                    to="/devops/"
                    className="a_dec"
                    id="service_7"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Devops</li>
                  </NavLink>
                  <NavLink
                    to="/automation-testing"
                    className="a_dec"
                    id="service_8"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    <li id="li_des">Automation Testing</li>
                  </NavLink>
                  <NavLink
                    to="/webscraping"
                    className="a_dec"
                    id="service_9"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {" "}
                    <li id="li_des">Web Scrapping</li>
                  </NavLink>
                </ul>
              </div>

              <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                <div className="container" id="content_design">
                  <h2 id="mad_head">
                    <span style={{ color: "orange", fontSize: "20px" }}>
                      Flutter{" "}
                    </span>
                    App Development
                  </h2>

                  <p style={{ textIndent: "50px" }}>
                    Flutter is an open-source UI development kit by Google that
                    enables the development of beautiful natively compiled
                    applications for Android, iOS, Windows, Google, Linux, Mac
                    and the web from a single code base. First introduced in
                    2017, our team has been proactive in researching and
                    implementing Flutter frameworks to create highly customised
                    mobile apps with rich UI that work seamlessly across
                    platforms.
                  </p>

                  <h2 id="border_head">
                    {" "}
                    <span style={{ color: "black" }}>What we offer?</span>
                  </h2>
                  <div>
                    <ul style={{ marginLeft: "-30px" }}>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        Faster app Development process: Flutter’s Hot reload
                        feature enables developers to build interactive UI, add
                        features and fix bugs faster by using a rich set of
                        fully customisable widgets. Our team works closely with
                        you to provide end-to-end design optimization and fast
                        turnaround time.
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        Native Performance: Flutter framework uses DART native
                        compilers and inbuilt widgets that can incorporate all
                        critical platform differences such as scrolling,
                        navigation, icons and fonts to build smooth animations
                        with a faster loading capacity. Our skilled team of
                        designers and developers work in tandem to build apps
                        that work seamlessly across platforms and have robust UI
                        and security features.
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        Expressive and Flexible UI: Flutter’s design frameworks
                        and widget library allow developers to delight end-users
                        with enthralling UI/UX designs built using Material
                        Design(Android) and Cupertino (iOS-flavor) widgets, rich
                        motion APIs, smooth natural scrolling, built with
                        platform awareness. Our team of experts has the required
                        expertise to build the right featured packed app that
                        will fit your business needs and budget.
                      </li>
                      <br></br>
                      <li>
                        <i
                          className="fas fa-angle-double-right"
                          style={{ marginRight: "5px", color: "red" }}
                        ></i>
                        A skilled team with in-depth understanding of Apple
                        design principles and guidelines with strong knowledge
                        on OOPs, Swift and Apple iOS lifecycle.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                      <NavLink
                        to="/service"
                        id="fa_siz_center"
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        All services
                      </NavLink>
                    </div>
                  </div>
                </div>

                <Footer />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
