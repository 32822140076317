import React from "react";
import ContactBanner from "../../Components/ContactPage/ContactBanner/ContactBanner";
import ContactMain from "../../Components/ContactPage/ContactMain/ContactMain";
import Footer1 from "../../Components/HomePage/Footer1/Footer1";
const Contact = () => {
  return (
    <div>
      <ContactBanner />
      <ContactMain />
      <Footer1 />
    </div>
  );
};
export default Contact;
